<template>
  <div>
    <p class=" mb-6">Ajustes detallado por producto</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>

          <v-col cols="4">
            <dos-fechas
              :fechas="[filtros.fecha.desde,filtros.fecha.hasta]"
              @getFechas="getFechas" :icon="false"></dos-fechas>
          </v-col>
          <v-col cols="4">
            <AreaSelect :clearable="true" :dense="true" v-model="filtros.idArea"  :outlined="true"></AreaSelect>
          </v-col>
          <v-col cols="4">
            <SelectMotivoAjuste :clearable="true" v-model="filtros.idMotivoAjuste"  :dense="true" :outlined="true"></SelectMotivoAjuste>
          </v-col>
          <v-col cols="3">
            <v-text-field hide-details="auto" v-model="filtros.numeroDocumento" label="NumeroDocumento" outlined dense></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field hide-details="auto" label="Barra Producto" v-model="filtros.barra"  outlined dense></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field hide-details="auto" label="Nombre Producto" v-model="filtros.nombreProducto"  outlined dense></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field hide-details="auto" label="Numero Lote" v-model="filtros.numeroLote"  outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn :loading="cargando" :disabled="cargando || !validar()" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <tabla-reporte @GetVer="getVer" :acciones="['VER']" :nombreReporte="nombreReporte" :cabeceraIn="cabecera"
          :datosIn="datos" :exportar="['EXCEL', 'PDF']"></tabla-reporte>

      </v-card-text>
    </v-card>
    <ModalViewAjuste ref="ModalViewAjusteRef" :VerButton="false"></ModalViewAjuste>
  </div>
</template>
  
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaReporte from '@/components/TablaReporte.vue';
import { ref } from '@vue/composition-api';
import AjusteServices from '@/api/servicios/AjusteServices';
import store from '@/store';
import AreaSelect from '../../archivo/area/componentes/AreaSelect.vue';
import DosFechas from '@/components/DosFechas.vue';
import FuncionesGenerales from '@/funciones/funciones'
import SelectMotivoAjuste from '../../archivo/MotivoAjuste/componentes/Select.vue';
import moment from 'moment';
import ModalViewAjuste from '../../ajuste/ajuste/componentes/ModalViewAjuste.vue';
// demos

export default {
  components: { TablaReporte, DosFechas, AreaSelect, SelectMotivoAjuste, ModalViewAjuste },
  setup() {
    const filtros = ref({

      fecha: {
        desde: moment().format('YYYY-MM-DD'),
        hasta: moment().format('YYYY-MM-DD')
      },
      Id: null,
      numeroDocumento: '',
      idArea: null,
      barra: '',
      nombreProducto: '',
      numeroLote: '',
      idMotivoAjuste: null,
      idProveedor: null,
      fechaAjusteDesde: null,
      fechaAjusteHasta: null
    })



    const nombreReporte = ref(`Reporte ajuste detalle por producto ${filtros.value.fecha.desde} ${filtros.value.fecha.hasta}`)

    const cabecera = ref([
      { text: 'ID', value: 'ajuste.id', },
      { text: 'Fecha Ajuste', value: 'ajuste.fechaAjuste', width: '130px', visible: true , format: { date: true } },
      { text: 'Documento', value: 'ajuste.numeroDocumento', width: '160px', visible: true },
      { text: 'Motivo', value: 'motivoAjuste.descripcion', width: '130px', visible: true },
      { text: 'Barra', value: 'productoSucursal.producto.barra', format: { small: true } , width: '130px', visible: true },
      { text: 'Producto', value: 'productoSucursal.producto.nombre', width: '130px', visible: true },
      { text: 'Lote', value: 'lote.numeroLote', width: '130px', visible: true },
      { text: 'Area', value: 'area.nombre', width: '130px', visible: true },
      { text: 'Cant ajus', value: 'totalUnidades', width: '130px', visible: true, align:"right"  },
      { text: 'Estatus', value: 'ajuste.estatus.descripcion', width: '130px', visible: true,  size: 'small', chip: true, color: ['primary', 'error', 'info'], valores: ['Finalizado', 'Anulado', 'borrador'] } 
    ])
    const datos = ref(
      [
      ]
    )
    const cargando = ref(false)
    const ModalViewAjusteRef = ref(null)



    const validar = () => {
      let validado = true

      return validado
    }

    const buscarDatos = () => {
      cargando.value = true
      AjusteServices.ConsultarAjusteDetalleReporte(
        {
          ...
          filtros.value,
          fechaAjusteDesde: FuncionesGenerales.formatoFecha(filtros.value.fecha.desde, 5),
          fechaAjusteHasta: FuncionesGenerales.formatoFecha(filtros.value.fecha.hasta, 5)
        })
        .then(response => {
          if (response.data.estatus = true) {
            datos.value = response.data.datos
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'error',
            })
          }

        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          cargando.value = false

        })
    }

    const getVer = (dato) => {
      console.log(dato)
      ModalViewAjusteRef.value.abrir(dato.idAjuste)
    }

    const getFechas = (datos) => {
      console.log(datos)
      filtros.value.fecha.desde = datos.desde
      filtros.value.fecha.hasta = datos.hasta
    }


    return {
      cabecera,
      datos,
      validar,
      buscarDatos,
      cargando,
      nombreReporte,
      getVer,
      filtros,
      FuncionesGenerales,
      getFechas,
      ModalViewAjusteRef
    }
  },
}
</script>
  